import globalContent from './const/globalContext';

// define module names
const modules = [
    'accordion',
    'ageRestriction',
    'anchor-link',
    'bmi-calc',
    'carousel',
    'codeChecker',
    'compared-image',
    'cta-popup',
    'form-validate',
    'header-video',
    'horizon',
    'locations',
    'menu-open',
    'news-results',
    'mobile-slider',
    'search-overlay',
    'select',
    'slider',
    'submenu',
    'tabs',
    'video-open',
];

// find modules on page
modules.forEach(async (module) => {
    const $el = document.querySelector(`.js-${module}`);
    if ($el) {
        // if found, load js with corresponding filename
        const importedModule = await import(`./modules/${module}.js`);
        importedModule.default();
    }
});

if (document.querySelector('.js-modal-open')) {
    import('./modules/modal-open.js');
}

// fire on all <form> elements
if (document.querySelector('form')) {
    import('./modules/form-conditions.js').then((module) => {
        module.default();
    });
}

if (globalContent.iScreenWidth > globalContent.mobileBreakpoint) {
    import('./modules/scroll-animations.js').then((module) => {
        module.default();
    });
}
